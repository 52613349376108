<template>

  <div class="row justify-content-between h-100">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 justify-content-between align-items-center bg-primary text-white" >
        <PersonalMenu :user="user"
        ></PersonalMenu>
    </div>
    <div class="col-lg-9 col-xl-9">
      <div class="d-flex flex-column">
        <div class="row align-items-center mt-10">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column px-10">
                <h1 class="card-label h1 font-weight-bolder">{{$t("PSEDIT2.PS")}}</h1>
                <h2 class="card-label h3 font-weight-bold">{{$t("PSEDIT2.AS")}}</h2>
            </div>
          </div>
        </div>
        <div class="row align-items-center my-10 px-10">
          <div class="col-xl-12">
            <div class="card card-custom card-shadow mb-3">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column mr-auto">
                    <h2 class="font-size-h2 text-dark font-weight-bolder mb-1 mr-10" v-if="user">{{user.name}} {{user.surname}}</h2>
                  </div>
                   <div class="card-toolbar">
                     <button
                         class="btn btn-primary text-uppercase font-weight-bolder"
                         type="button"
                         @click.prevent="submitData"
                         href="/personal-space"
                     >
                       {{$t("PSEDIT2.SALVA")}}
                     </button>

                  </div>
                </div>
              </div>

            </div>
            <div class="card mb-3 shadow-sm">
              <b-button v-b-toggle.collapse-biography class="btn btn-block btn-white text-primary p-4 text-left">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  >
                  <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                  >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      ></path>
                      <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                  </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder">{{$t("PSEDIT2.BIOGRAFIA")}}</span>
              </b-button>
              <b-collapse visible id="collapse-biography">
                <div class="card-body">
                  <div class="row px-5">
                    <div class="col-lg-12 col-sm-12">
                      <vue-editor
                          id="input-biography"
                          :editor-toolbar="customToolbar"
                          placeholder=""
                          :tag="'textarea'"
                          v-model="user.biography"
                          row="10"
                      >
                      </vue-editor>

                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
<!--            <div class="card mb-3 shadow-sm">
              <b-button v-b-toggle.collapse-budget class="btn btn-block btn-white text-primary p-4 text-left">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  >
                  <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                  >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      ></path>
                      <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                  </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder">{{$t("PSEDIT2.BUDGET")}}</span>
              </b-button>
              <b-collapse id="collapse-budget">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                      <h5>{{$t("PSEDIT2.REDDITO")}}</h5>
                      <div class="py-4 px-5 h3 text-primary bg-gray-100">
                        <div class="card-text h4 font-weight-normal mt-4">
                          <b-form-input
                              id="input-annualIncome"
                              class="bg-default text-primary font-weight-bolder"
                              type="number"
                              name="annualIncome"
                              v-model="user.annualIncome"
                              placeholder=""
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <h5>{{$t("PSEDIT2.BUDGETDADONARE")}}</h5>
                      <div class="py-4 px-5 h3 text-primary bg-gray-100">
                        <div class="card-text h4 font-weight-normal mt-4">
                          <b-form-input
                              id="input-participatoryBudget"
                              class="bg-default text-primary font-weight-bolder"
                              type="number"
                              name="participatoryBudget"
                              v-model="user.participatoryBudget"
                              placeholder=""
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>-->

            <div class="card mb-3 shadow-sm">
              <b-button v-b-toggle.collapse-education class="btn btn-block btn-white text-primary p-4 text-left">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  >
                  <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                  >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      ></path>
                      <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                  </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder">{{$t("PSEDIT2.EDULAV")}}</span>
              </b-button>
              <b-collapse visible id="collapse-education">
                <div class="card-body">
                  <div class="row p-5">
                    <div class="col-12 col-lg-6 mb-7">
                      <h5>{{$t("PSEDIT2.EDU")}}</h5>
                      <div class="card-text h4 font-weight-normal mt-4">
                        <vue-editor
                            id="input-education"
                            :editor-toolbar="customToolbar"
                            placeholder=""
                            :tag="'textarea'"
                            v-model="user.education"
                            row="10"
                        >
                        </vue-editor>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 mb-7">
                      <h5>{{$t("PSEDIT2.LAV")}}</h5>
                      <div class="card-text h4 font-weight-normal mt-4">
                        <vue-editor
                            id="input-job"
                            :editor-toolbar="customToolbar"
                            placeholder=""
                            :tag="'textarea'"
                            v-model="user.job"
                            row="10"
                        >
                        </vue-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
<!--            <div class="card mb-3  shadow-sm">
              <b-button v-b-toggle.collapse-behaviour class="btn btn-block btn-white text-primary p-4 text-left">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  >
                  <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                  >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      ></path>
                      <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                  </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder">{{$t("PSEDIT2.COMPORTAMENTO")}}</span>
              </b-button>
              <b-collapse id="collapse-behaviour">
                <div class="card-body">
                  <div class="row p-5">
                    <div class="col-lg-12 mb-7">
                      <h5>{{$t("PSEDIT2.ATTEGGIAMENTO")}} </h5>
                      <div class="card-text h4 font-weight-normal mt-4">
                        <vue-editor
                            id="input-behavior"
                            :editor-toolbar="customToolbar"
                            placeholder=""
                            :tag="'textarea'"
                            v-model="user.behavior"
                            row="10"
                        >
                        </vue-editor>
                      </div>
                    </div>
                    <div class="col-lg-12 mb-7">
                      <h5>{{$t("PSEDIT2.VISIONEPOLITICA")}}</h5>
                      <div class="card-text h4 font-weight-normal mt-4">
                        <vue-editor
                            id="input-politicalView"
                            :editor-toolbar="customToolbar"
                            placeholder=""
                            :tag="'textarea'"
                            v-model="user.politicalView"
                            row="10"
                        >
                        </vue-editor>
                      </div>
                    </div>
                    <div class="col-lg-12 mb-7">
                      <h5>{{$t("PSEDIT2.INFOVARIE")}}</h5>
                      <div class="card-text h4 font-weight-normal mt-4">
                        <vue-editor
                            id="input-additionalInfo"
                            :editor-toolbar="customToolbar"
                            placeholder=""
                            :tag="'textarea'"
                            v-model="user.additionalInfo"
                            row="10"
                        >
                        </vue-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>-->

          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-sm-block col-1 justify-content-between align-items-center">
      <div class="d-flex flex-column mt-10 mr-5 align-items-center">
        <router-link to="">
          <a
            href="#"
            class="mb-10 btn btn-icon w-auto d-flex btn-lg px-2"
            @click.prevent="alertInfo"
            id="pop-info"
          >
            <span class="symbol symbol-rounded">
              <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                <span class="svg-icon svg-icon-white svg-icon-2x">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                        <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
                        <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
               </span>
            </span>
          </a>
        </router-link>
      </div>
    </div>
  </div>


</template>

<script>
import ApiService from "@/core/services/api.service";
//import ProcessCardPreview from "@/view/pages/process/partials/ProcessCardPreview.vue";
// import GroupCardPreview from "@/view/pages/groups/partials/GroupCardPreview";
import PersonalMenu from "@/view/layout/common/PersonalMenu.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";

import {VueEditor} from "vue2-editor";

export default {
  name: "personalSpace",
  components: {
    PersonalMenu,
    VueEditor,
  },
  data() {
    return {
      processes: [],
      groups: [],
      user: [],

      /*
      |--------------------------------------------------------------------------
      | EDITOR
      |--------------------------------------------------------------------------
      */
      customToolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],


    }
  },
  computed:{
    ...mapGetters([
      "currentUser"
    ]),
  },
  mounted() {
    // this.getScenarios();
    // this.getChallenges();
    //this.getProcesses();
    // this.getGroups();
   // this.getOrganizations();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.currentUser.name || this.currentUser.surname ? `${this.currentUser.name} ${this.currentUser.surname}` : this.currentUser.username },
      { title: this.$t('PS.MODIFICA') }
    ]);


  },
  created(){
    this.getUser();
  },
  methods: {
    getUser() {

      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("users-expanded/" + this.currentUser.id, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            // console.log(res.data);
            this.user = res.data;
            // this.$store.dispatch(SET_CURRENT_PROCESS, this.process);
          })
          .catch(() => {
            // .catch(({ response }) => {
            //   console.log(response);
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
          });
      });
    },
    // alertEdit(){
    //   Swal.fire({
    //     title: this.$t("PS.ALERTMODIFICADISABILITATATITLE"),
    //     html:this.$t("HOME.ALERTMODIFICADISABILITATA"), /* #key_loc) */
    //     icon: "error",
    //     confirmButtonClass: "btn btn-danger"
    //   });
    // },
    alertInfo(){
      Swal.fire({
        title: this.$t("HOME.ALERT2TITLE"),
        html: this.$t("HOME.ALERT2"),
        icon: "warning",
        confirmButtonClass: "btn btn-warning"
      });
    },

    submitData() {

      return new Promise((resolve, reject) => {
        ApiService.put(this.$apiResourceOpen + "/users-unassociated/locs/it", this.user)
            .then(res => {
              console.log('USER MODIFIED: ' + res.data);
              Swal.fire({
                title:  this.$t("PSEDIT.SUCCESSO"),
                text: this.$t("PSEDIT.MODOK"),
                icon: "success",
                confirmButtonClass: "btn btn-secondary"
              });
            })
            .catch(() => {
              reject(0);
            });
      });
    }
  }
};
</script>


